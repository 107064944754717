import {
    resetPasswoed,
    // 账号修改手机号
    resetPasswordByAcconut
} from './api'
import CryptoJS from "crypto-js";

export default {
    name: 'findPassword',
    data() {
        return {
            newPassword: '',
            surePassword: '',
            specialword: '< >’)+/&',
            // 接口参数
            parameter: {},
            visible: false,
            phoneORaccount: true,
            mimazhong: false,
            mimachang: false,
            teshu: false,
            butong: false,
            mimakong: false,
            bteshu: false
        }
    },
    mounted() {
        if (sessionStorage.getItem('phoneCanUse')) {
            this.parameter = JSON.parse(sessionStorage.getItem('phoneCanUse'))
        } else {
            this.parameter = JSON.parse(sessionStorage.getItem('phoneNoUse'))
            this.phoneORaccount = false
        }
        this.parameter.msmCode = sessionStorage.getItem('msmCode');
        document.querySelector('body').setAttribute('style', 'background-color:#F0F3F7')
    },
    beforeDestroy() {
        document.querySelector('body').removeAttribute('style', 'background-color:#FFFFFF')
    },
    methods: {
        // 登录
        submit() {
            var that = this;
            var regEn = /[`~!@#$%^&*()_+<>?:"{},./;'[\]]/im;
            if (that.newPassword == '') {
                that.mimakong = true;
                return;
            }
            // else if (!(/^[^\u4e00-\u9fa5]+$/.test(that.newPassword))) {
            //     that.mimazhong = true;
            //     return;
            // }

            else if (that.newPassword.length < 8 || that.newPassword.length > 20) {
                that.mimazhong = false;
                that.mimakong = false;
                that.mimachang = true;
                return;
            }

            // else if (!(regEn.test(that.newPassword))) {
            //     that.mimazhong = false;
            //     that.mimachang = false;
            //     that.mimakong = false;
            //     that.teshu = true;
            //     return;
            // }

            // else if (that.newPassword.indexOf("<") >= 0 || that.newPassword.indexOf(">") >= 0 || that.newPassword.indexOf("'") >= 0 || that.newPassword.indexOf("+") >= 0 ||
            //     that.newPassword.indexOf(")") >= 0 || that.newPassword.indexOf("/") >= 0 || that.newPassword.indexOf("&") >= 0) {
            //     that.mimazhong = false;
            //     that.mimachang = false;
            //     that.teshu = false;
            //     that.mimakong = false;
            //     that.bteshu = true;
            //     return;
            // }

            else if (that.newPassword !== that.surePassword) {
                that.mimazhong = false;
                that.mimachang = false;
                that.teshu = false;
                that.bteshu = false;
                that.mimakong = false;
                that.butong = true
                return;
            } else {
                this.mimakong = false
                this.mimachang = false
                this.butong = false
            }
            // 密码加密
            let password = this.$util.passwordEncryPtion(this.surePassword)
            // 赋值密码为加密后的
            that.parameter.pwd =  password

            let data = that.parameter;
            if (this.phoneORaccount) {
                resetPasswoed(data).then(res => {
                    if (res.data.status == 'true') {
                        sessionStorage.removeItem('msmCode')
                        this.$success({
                            title: '信息',
                            // JSX support
                            content: ( < div > < p > 修改密码成功， 请重新登录！ </p> </div > ),
                            onOk() {
                                that.success()
                            },
                        });
                    } else {
                        this.$message.warning(res.data.msg)
                    }
                })
            } else {
                let data = {
                    custLoginName: that.parameter.custLoginName,
                    custFullName: that.parameter.custFullName,
                    custLegalPerson: that.parameter.custLegalPerson,
                    password: password
                }
                resetPasswordByAcconut(data).then(res => {
                    if (res.data.status == 'true') {
                        sessionStorage.removeItem('msmCode')
                        this.$success({
                            title: '信息',
                            // JSX support
                            content: ( < div >
                                <
                                p > 修改密码成功， 请重新登录！ </p> </div >
                            ),
                            onOk() {
                                that.success()
                            },
                        });
                    } else {
                        this.$message.warning(res.data.msg)
                    }
                })
            }

        },
        success() {
            this.$router.push('login');
        },
        tologin() {
            this.$router.push('/login');
        }
    }
}