import http from "@/utils/request";

// post
export function login(data) {
    return http({
        method: "post",
        url: "/webLogin.nd",
        data
    });
}

// 获取验证码
export function imgCode() {
    return http({
        method: 'get',
        url: '/checkImg2.nd'
    })
}

// 重设密码 手机号
export function resetPasswoed(data) {
    return http({
        method: 'post',
        url: '/resetPwdByPhone.nd',
        data
    })
}

// 账号名字
export function resetPasswordByAcconut(data) {
    return http({
        method: 'post',
        url: '/modifyByQuestion.nd',
        data
    })
}
